import { Link } from "react-router-dom"
import Logo from '../Imagenes/logo_azul.png'
import { FaLinkedin } from "react-icons/fa"
import { FaFacebook } from "react-icons/fa"
import { AiFillYoutube } from "react-icons/ai"
import { AiFillInstagram } from "react-icons/ai"
import imgiess from '../../src/Imagenes/iess_issfa.png'

const Footer = () => {


  return (
    <footer>
        <div className="container footer__container">
            <article>
                <Link to="/" className='logo'>
                    <img className="footer_logo" src={Logo} alt="Footer Logo" />
                </Link>
                <p>
                Exequiales IESS es una compañía del Instituto Ecuatoriano de Seguridad Social para brindar servicios funerarios a  afiliados, jubilados, beneficiarios de montepío y público en general. 

                </p>
                <div className="footer__socials" id="footer__socials">
                    <a href="https://www.linkedin.com/in/exequiales-iess/" target="_blank" rel='noreferrer noopener'><FaLinkedin/></a>
                    <a href="https://www.facebook.com/ExequialesIESSEcuador" target="_blank" rel='noreferrer noopener'><FaFacebook/></a>
                    <a href="https://www.youtube.com/channel/UCyoNBfNWM3Ml11YMvawMkdA" target="_blank" rel='noreferrer noopener'><AiFillYoutube/></a>
                    <a href="https://www.instagram.com/exequiales_iess/" target="_blank" rel='noreferrer noopener'><AiFillInstagram/></a>
                </div>
            </article>
            <article>
                <h4>Exequiales</h4>
                <Link className="linka" to="/about">Sobre Nosotros</Link>
                <Link className="linka" to="/contact">Contáctanos</Link>
                <Link className="linka" to="/funeralServices">Servicios Funerales</Link>
                <Link className="linka" to="/wakeRooms">Salas de Velación Quito</Link>
                <Link className="linka" to="/wakeRoomsGYE">Salas de Velación Guayaquil</Link>
            </article>
            <article>
                <h4>Ponerse en Contacto</h4>
                <Link className="linka" to="/contact">Contáctanos</Link>
                <p>Quito: 099 800 5771 – (02) 2 272 803</p>
                <p>Guayaquil: 098 766 8617 – (04) 239 7302</p>
            </article>
          <article>
                <img className="imgiess" src={imgiess} alt="iess-issfa" />
            </article> 
        </div>
        <div className="footer__copyright">
            <small>2024 Exequiales IESS &copy; Todos los Derechos Reservados</small>
        </div>
    </footer>
  )
}

export default Footer

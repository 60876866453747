import { BrowserRouter, Routes, Route } from 'react-router-dom'


import Home from './pages/home/Home';
import About from './pages/about/About';
import Blog from './pages/blog/Blog';
import Contact from './pages/contact/Contact';
import FuneralServices from './pages/funeralServices/FuneralServices';
import NotFound from './pages/notFound/NotFound';
import WakeRooms from './pages/wakeRooms/WakeRooms';
import WakeRoomsGYE from './pages/wakeRoomsGYE/WakeRooms';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import WhatsAppWidget from './components/WhatsAppWidget';

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<Home/>}/>
        <Route path='about' element={<About/>}/>
        <Route path='blog' element={<Blog/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='funeralServices' element={<FuneralServices/>}/>
        <Route path='*' element={<NotFound/>}/>
        <Route path='wakeRooms' element={<WakeRooms/>}/>
        <Route path='wakeRoomsGYE' element={<WakeRoomsGYE/>}/>
      </Routes>
      <Footer/>
      <WhatsAppWidget />
    </BrowserRouter>
  )
}

export default App

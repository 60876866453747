

export const links = [
    {
        name: "Inicio",
        path: '/'
    },
    {
        name: "Nosotros",
        path: '/about'
    },
    {
        name: "Preguntas Frecuentes",
        path: '/blog'
    },
    {
        name: "Servicios Funerarios",
        path: '/funeralServices'
    },
    {
        name: "Salas de Velación Quito",
        path: '/wakeRooms'
    },
    {
        name: "Salas de Velación Guayaquil",
        path: '/WakeRoomsGYE'
    },
    {
        name: "Contacto",
        path: '/contact'
    }
]






export const programs = [
    {
        id: 1,
        title: "AUXILIO FUNERARIO PARA INHUMACIÓN",
        info: "Es el derecho que tenemos todos los afiliados, jubilados y/o beneficiarios de montepío.Este beneficio está diseñado para apoyar a las familias en la gestión de los gastos relacionados con el proceso de inhumación, ofreciendo un respaldo económico en momentos difíciles.",
        path: "/funeralServices"
    },
    {
        id: 2,
        title: "AUXILIO FUNERARIO PARA CREMACIÓN",
        info: "Es el derecho que tenemos todos los afiliados, jubilados y/o beneficiarios de montepío.Este beneficio está diseñado para ayudar a las familias con los gastos asociados a este proceso, brindando un apoyo económico en momentos difíciles.",
        path: "/funeralServices"
    },
    {
        id: 3,
        title: "CAPILLA ARDIENTE",
        info: "Damos el servicio de capilla ardiente para permitir la honrar y despedir a sus seres queridos en el domicilio de forma pública. Este servicio brinda un espacio para el duelo y el recuerdo, facilitando una despedida significativa en un entorno familiar.",
        path: "/funeralServices"
    },
    {
        id: 4,
        title: "INHUMACIÓN Y CREMACIÓN PARA PARTICULARES",
        info: "Como funeraria, damos servicios de inhumación y cremación para personas particulares. Nos encargamos de gestionar todos los aspectos relacionados con el proceso, asegurando un servicio profesional y respetuoso adaptado a las necesidades de cada familia.",
        path: "/funeralServices"
    }
]








export const about = [
    {
        id: 1,
        title: "Salas de Velación QUITO",
        desc: "Nuestras salas de velación en Quito ofrecen opciones premium y VIP, equipadas con una capilla, cafetería, sala de estar, baños privados y WiFi. Además, disponemos de equipos para la transmisión en vivo de las ceremonias, misa virtual, parqueaderos y servicio de seguridad privada para garantizar comodidad y tranquilidad durante el proceso de despedida."
    },
    {
        id: 2,
        title: "Salas de Velación GUAYAQUIL",
        desc: "En Guayaquil contamos con dos salas de velación, cada una equipada con cafetería, sala de estar, baños privados y WiFi. Ofrecemos equipos para la transmisión de la ceremonia y misa virtual, así como parqueaderos y servicio de seguridad privada para asegurar la comodidad y tranquilidad durante el proceso de despedida."
    }
]









export const faqs = [
    {
        id: 1,
        question: "¿CUÁL ES EL TARIFARIO PARA EL FONDO MORTUORIO?",
        answer: "Para 2024, el Instituto Ecuatoriano de Seguridad Social (IESS) estableció el monto de 1437,90 dólares para el auxilio de funerales."
    },
    {
        id: 2,
        question: "¿EXEQUIALES IESS ES UNA EMPRESA SOLO PARA AFILIADOS/ JUBILADOS?",
        answer: "En Exequiales IESS está principalmente orientado a los afiliados, jubilados y pensionistas del Instituto Ecuatoriano de Seguridad Social (IESS) y sus familias. Sin embargo tambien ofrecemos sus servicios al público en general."
    },
    {
        id: 3,
        question: "¿QUÉ ES EL FONDO MORTUORIO O AUXILIO DE FUNERALES?",
        answer: "El Fondo Mortuorio, también conocido como Auxilio de Funerales, es una ayuda económica que otorga el Instituto Ecuatoriano de Seguridad Social (IESS) a los deudos de un afiliado, jubilado o beneficiario de montepío que ha fallecido. Este subsidio tiene como objetivo proporcionar un apoyo financiero para cubrir los gastos funerarios y asegurar la tranquilidad de la familia en estos momentos difíciles."
    },
    {
        id: 4,
        question: "¿LOS JUBILADOS/ AFILIADOS AL SEGURO SOCIAL CAMPESINO TAMBIÉN TIENEN DERECHO AL AUXILIO FUNERARIO?",
        answer: "El Seguro Social Campesino entrega prestaciones de salud y económicas. Las prestaciones de salud consisten en atención médica, ginecológica y odontológica; y las económicas en el pago mensual de jubilaciones por vejez e invalidez; y también el pago auxilio de funerales."
    },
    {
        id: 5,
        question: "ACABO DE PERDER A UN FAMILIAR ¿QUÉ DEBO HACER?",
        answer: (
            <>
                Lamentamos mucho tu pérdida. Para recibir asistencia inmediata, por favor, contáctanos a los siguientes números:<br />
                Quito: 099 800 5771<br />
                Guayaquil: 098 766 8617<br />
                Estamos disponibles las 24 horas del día, los 365 días del año, para ofrecerte el apoyo que necesitas en estos momentos difíciles.
            </>
        )
    },
    {
        id: 6,
        question: "¿QUÉ CUBRE EL IESS POR FALLECIMIENTO?",
        answer: (
            <>
                Esta prestación cubre los siguientes servicios:<br />
                •	Velación: Servicio para la exposición del cuerpo.<br />
                •	Carroza: Vehículo para el transporte del difunto.<br />
                •	Inhumación o cremación: Opciones para la disposición final del cuerpo.<br />
                •	Cofre mortuorio o urna cineraria: Contenedor para el cuerpo o las cenizas.<br />
                •	Traslados: Transporte desde la sala de velación hasta el camposanto.<br />
                •	Servicio religioso: Ceremonia religiosa acorde a las creencias del fallecido.<br />
                •	Trámites legales: Gestión de los documentos y procedimientos necesarios.<br />
            </>
        )
   
    }
]








export const testimonials = [
    {
        id: 1,
        name: "Paulina Gahui",
        quote: "En un momento dificil exequiales del iess estuvo ahi para ayudar y aliviar el dolor que sentiamos en ese momento. Aparte de ser una empresa del iess nos brindo todos los beneficios que el afiliado necesito en ese momento.",
        job: "",
        //avatar: require("./images/")
    },
    {
        id: 2,
        name: "Sara Recalde",
        quote: "Recomiendo por experiencia, un excelente servicio. Calidad excelente de los trabajadores. No existe inconveniente en la cobertura de gastos pues trabajan con los fondos de auxilio de funerales que nos otorga el IESS.",
        job: "",
        //avatar: require("./images/avatar2.jpg")
    },
    {
        id: 3,
        name: "Jennifer Castillo",
        quote: "Recomiendo sus servicios, tienen instalaciones amplias y brindan una guía completa sobre todos los trámites legales.",
        job: "",
        //avatar: require("./images/avatar3.jpg")
    },
    {
        id: 4,
        name: "Janneth Salgado",
        quote: "El personal es muy cálido y siempre dispuesto a ayudar",
        job: "",
       // avatar: require("./images/avatar4.jpg")
    },
    {
        id: 5,
        name: "Carlos Erazo",
        quote: "Un servicio muy bueno. La atención muy amable.",
        job: "",
       // avatar: require("./images/avatar5.jpg")
    }
]







export const plans = [
    {
        id: 1,
        name: 'Servicio VIP Cremación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '> Tanatopraxia', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Cofre', available: true},
            {feature: '> Velación por 24 horas', available: true},
            {feature: '> Arreglos florales', available: true},
            {feature: '> Cafetería VIP', available: true},
            {feature: '> Servicio religioso con acompañamiento musical', available: true},
            {feature: '> Libro de condolencias', available: true},
            {feature: '> Tarjetas de agradecimiento', available: true},
            {feature: '> Cremación', available: true},
            {feature: '> Urna cenizaria', available: true}
        ]
    },
    {
        id: 2,
        name: 'Servicio VIP Inhumación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '> Tanatopraxia', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Cofre de madera', available: true},
            {feature: '> Velación por 24 horas', available: true},
            {feature: '> Arreglos florales', available: true},
            {feature: '> Cafetería VIP', available: true},
            {feature: '> Servicio religioso con acompañamiento musical', available: true},
            {feature: '> Libro de condolencias', available: true},
            {feature: '> Tarjetas de agradecimiento', available: true}
        ]
    },
    {
        id: 3,
        name: 'Servicio PREMIUM Cremación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '>Tanatopraxia', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Cofre', available: true},
            {feature: '> Velación por 24 horas', available: true},
            {feature: '> Arreglos florales', available: true},
            {feature: '> Cafetería Premium', available: true},
            {feature: '> Servicio religioso con acompañamiento musical', available: true},
            {feature: '> Misa primer mes con acompañamiento musical', available: true},
            {feature: '> Libro de condolencias', available: true},
            {feature: '> Tarjetas de agradecimiento', available: true},
            {feature: '> Cremación', available: true},
            {feature: '> Urna cenizaria', available: true}
        ]
    },
    {
        id: 4,
        name: 'Servicio PREMIUM Inhumación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '> Tanatopraxia', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Cofre', available: true},
            {feature: '> Velación por 24 horas', available: true},
            {feature: '> Arreglos florales', available: true},
            {feature: '> Cafetería Premium', available: true},
            {feature: '> Servicio religioso con acompañamiento musical', available: true},
            {feature: '> Misa primer mes con acompañamiento musical', available: true},
            {feature: '> Libro de condolencias', available: true},
            {feature: '> Tarjetas de agradecimiento', available: true}
        ]
    },
    {
        id: 4,
        name: 'Capilla Ardiente Cremación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Tanatopraxia', available: true},
            {feature: '> Cofre', available: true},
            {feature: '> Arreglos florales', available: true},
            {feature: '> Libro de Condolencias', available: true},
            {feature: '> Servicio Religioso', available: true},
            {feature: '> Accesorios para capilla ardiente', available: true},
            {feature: '> Cremación', available: true},
            {feature: '> Urna cenizaria', available: true}
        ]
    },
    {
        id: 4,
        name: 'Capilla Ardiente Inhumación',
        desc: '',
        features: [
            {feature: '> Traslado dentro del cantón Quito', available: true},
            {feature: '> Trámites Legales', available: true},
            {feature: '> Formolización', available: true},
            {feature: '> Tanatopraxia', available: true},
            {feature: '> Cofre', available: true}
        ]
    }
]









import Image from '../Imagenes/exe_quito.jpg'
import Image1 from '../Imagenes/IMG_38211.JPG'
import SectionHead from './SectionHead'
import {GiCutDiamond} from 'react-icons/gi'
import {about} from '../data'
import Card from '../UI/Card'

const About = () => {
  return (
    <section className='about'>
      <div className="container about__container">
        <div className="about__left">
          <div className="about__imagen">
            <img src={Image} alt="Values Imagen" />
          </div>
          <div className="about__image">
            <img src={Image1} alt="Values Imagen" />
          </div>
        </div>
        <div className="about__right">
          <SectionHead icon={<GiCutDiamond/>}  title="Salas de Velación"/>
          <p>Exequiales Iess es una compañía del Instituto Ecuatoriano de Seguridad Social (IESS) 
            que ofrece servicios funerarios mediante el uso del Fondo de Auxilio para Funerales. 
            Todos los pensionistas de jubilación, pensionistas de montepío y afiliados al IESS que 
            hayan realizado al menos 6 aportes mensuales en los 12 meses previos a su fallecimiento tienen 
            derecho a acceder a este fondo.
          </p>
            <div className="about__wrapper">
              {
                about.map(({id,  title, desc}) => {
                  return <Card key={id} className="about__value">
                    <h4 class="titulo_car">{title}</h4>
                    <small>{desc}</small>
                  </Card>
                })
              }
            </div>
        </div>
      </div>
    </section>
  )
}

export default About

import Header from '../../components/Header'
import HeaderImage from '../../Imagenes/INSTALACIONES/DSC_3301.jpg'
import StoryImage from '../../Imagenes/empresa.jpg'
import VisionImage from '../../Imagenes/visionexe.jpg'
import MissionImage from '../../Imagenes/misionexe.jpg'
import ValuesImage from '../../Imagenes/values.jpg'
import React, { useEffect } from 'react';
import './about.css'

const About = () => {

  useEffect(() => { /*Para ver desde el principio de la página */
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Header title="Sobre Nosotros" image={HeaderImage}>
    Comprendemos y compartimos los sentimientos de los demás, la empatía nos 
    permite ver las cosas desde la perspectiva del otro en vez de la nuestra.
    </Header>

    <section className="about__story">
      <div className="container about__story-container">
        <div className='about__section-image'>
          <img src={StoryImage} alt="Our Story Imagen" />
        </div>
        <div className="about__section-content">
          <h1>Nuestra Historia</h1>
          <p>
          <br></br>
          Exequiales IESS es una compañía acreditada por el Instituto Ecuatoriano de Seguridad Social como prestadora
          de Servicios Funerarios, consolidada en el año 2014 con capital accionario del IESS y BIESS. Su objetivo es
          brindar tranquilidad a las familias de los afiliados al IESS, pensionistas de montepío, jubilados y al público en
          general, entregando un servicio de calidad que se adapte a sus necesidades
          </p>
        </div>
      </div>
    </section>
    
    <section className="about__vision">
      <div className="container about__vision-container">
        <div className="about__section-content">
          <h1>Nuestra Visión</h1>
        
          <p>
          <br></br>
          Ser una empresa líder en la prestación de servicios funerarios a nivel nacional mediante nuestra
          atención de calidad y servicio integral basado en la ética, respeto y cultura organizacional
          orientada al cliente.

          </p>
        </div>
        <div className='about__section-image'>
          <img src={VisionImage} alt="Our Vision Imagen" />
        </div>
      </div>
    </section>

    <section className="about__mission">
      <div className="container about__mission-container">
        <div className='about__section-image'>
          <img src={MissionImage} alt="Our Mission Imagen" />
        </div>
        <div className="about__section-content">
          <h1>Nuestra Misión</h1>
          <p>
          Ofrecer servicios funerarios de alta calidad a los afiliados, jubilados, pensionistas de montepío del
          IESS y público en general; pensando siempre en su tranquilidad, seguridad, paz y confianza.

          </p>
        </div>
      </div>
    </section>

    <section className="about__values">
      <div className="container about__values-container">
        <div className="about__section-content">
          <h1>Nuestros Valores</h1>
          <p>
            Respeto
            <br></br>
            Nos manejamos con el máximo respeto hacia todo lo que involucra la prestación de
            servicios funerarios; el difunto, sus familiares y las decisiones que tomen. Así también
            con los profesionales quienes conforman nuestra empresa.
            </p>
            <p>
            Solidaridad
            <br></br>
            La importancia y la necesidad de nuestros servicios en los momentos más difíciles, nos
            hacen conscientes del nivel de solidaridad y empatía que nuestro trabajo requiere.
            <p>
            </p>
            Calidad
            <br></br>
            Brindamos un servicio de calidad en todos los ámbitos que involucra la prestación de
            servicios funerarios (instalaciones, equipos, atención al cliente), garantizando la
            tranquilidad y seguridad del cliente.
          </p>        
          <p>
            Responsabilidad
            <br></br>
            Cumplimos con todos los estándares y obligaciones que nos permiten brindar un
            servicio responsable, cuidadoso y diligente con las necesidades de las familias de los
            fallecidos y clientes en general.
          </p>    
        </div>
        <div className='about__section-image'>
          <img src={ValuesImage} alt="Our Values Imagen" />
        </div>
      </div>
    </section>

    </>
  )
}

export default About
import { Card } from 'react-bootstrap'
import Header from '../../components/Header'
import HeaderImage from '../../Imagenes/INSTALACIONES/DSC_3272.jpg'
import HeaderImage1 from '../../Imagenes/blog1.jpg'
import HeaderImage2 from '../../Imagenes/blog2.jpg'
import React, { useState, useEffect } from 'react';
import FAQs from '../../components/FAQs';

import './blog.css'



const Blog = () => {

  useEffect(() => { /*Para ver desde el principio de la página */
    window.scrollTo(0, 0);
  }, []);

  const [mostrarTexto, setMostrarTexto] = useState(false);

  function toggleTexto() {
    setMostrarTexto(!mostrarTexto);
  }

  const [mostrarTexto1, setMostrarTexto1] = useState(false);

  function toggleTexto1() {
    setMostrarTexto1(!mostrarTexto1);
  }



  return (
    

    <>   
    <Header title="Blog" image={HeaderImage}>
    Brindamos a los usuarios acceso a la información del blog lo cual les ayuda a obtener contenido relevante y valioso, y a establecer una conexión más profunda con la página web y su temática. 
    </Header>
    
    <>
    <FAQs path="/lfaqs"/>
    </>

    <section className="blog">
      <div class="section__head undefined blog__container1"><span><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="60" viewBox="0 0 50 50">
      <path d="M 15 3 L 15 19.9375 L 19 17.90625 L 23 20 L 23 3 Z M 11 6 C 7.636719 6 5 8.636719 5 12 L 5 33 C 5 36.363281 7.636719 39 11 39 L 12 39 L 12 47.21875 L 21.375 39 L 40 39 C 43.363281 39 46 36.363281 46 33 L 46 12 C 46 8.636719 43.363281 6 40 6 L 25 6 L 25 22.375 L 19 30 L 13 22.375 L 13 6 Z M 19 20.09375 L 15.28125 21.96875 L 17.65625 25 L 20.3125 25 L 22.65625 22 Z M 19.125 30 L 44 30 L 44 33 C 44 35.242188 42.242188 37 40 37 L 23.53125 37 Z"></path>
      </svg></span><h2 class="blog_titulo1">Blog</h2></div>
    </section>     


    <section className="blog">
    <div className="blog__container">
      <Card>
          <div className='blog__seccion'>
          <img className='blogg__img' src={HeaderImage1} alt="" />  
          <h2>DESPUÉS DEL DUELO</h2> 
          <p>
          Aunque no hay forma de evitar los sentimientos intensos de dolor, existen formas más saludables de aceptar su pérdida.
          </p>
          <div>
           {mostrarTexto &&
              <p>
                Sea cual sea su relación con la persona fallecida, es importante recordar que todos vivimos el duelo de formas diferentes. No existe una única forma de reaccionar. 
                Cuando pierde a alguien importante en su vida, está bien sentir lo que siente. Algunas personas expresan su dolor llorando y otras nunca derraman una lágrima, pero 
                eso no significa que sientan menos la pérdida.No se juzgue, ni piense que debería comportarse de otra manera ni intente imponer una duración a su duelo. El duelo 
                por la muerte de una persona toma tiempo. Para algunos, ese tiempo se mide en semanas o meses, para otros en años.Permítase sentir. El proceso de duelo y aflicción 
                puede desencadenar muchas emociones intensas e inesperadas. Pero el dolor de su duelo no desaparecerá más rápido si lo ignora. De hecho, intentar hacerlo puede empeorar 
                las cosas a largo plazo. Para encontrar finalmente el modo de aceptar su pérdida, necesitará enfrentar el dolor de forma activa. Como dijo el consejero de duelo y escritor 
                Earl Grollman: “La única cura para las penas es permitirse sentirlas” 
              </p>
            }
            <button className='btn-read' onClick={toggleTexto}>
              {mostrarTexto ? 'Leer menos' : 'Leer más'}
            </button>
          </div>     
        </div>
      </Card>
      </div>

      <div className="blog__container">
      <Card>
      <div className='blog__seccion'>
      <img className='blogg__img' src={HeaderImage2} alt="" />  
          <h2>DÍA INTERNACIONAL DE LA FAMILIA</h2> 
          <p>
          El 15 de Mayo es el Día Internacional de las Familias, proclamado por la Asamblea General de las Naciones Unidas en 1993
          </p>
          <div>
           {mostrarTexto1 &&
              <p>
                Las Naciones Unidas consideran que la familia constituye la unidad básica de la sociedad. En este contexto, el Día Internacional 
                de las Familias nos da la oportunidad de reconocer, identificar y analizar cuestiones sociales, económicas y demográficas que afectan 
                a su desarrollo y evolución, es por ello que, en 1994 la Asamblea General de las Naciones Unidas proclamó este día oficial, tomando en 
                cuenta que la familia es el núcleo central de cualquier sociedad.
              </p>
            }
            <button className='btn-read' onClick={toggleTexto1}>
              {mostrarTexto1 ? 'Leer menos' : 'Leer más'}
            </button>
          </div>      
        </div>
      </Card>     
      </div>
    </section>
    </>
  )
}

export default Blog
